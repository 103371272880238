import React from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";

function Breadcrumb() {
  return (
    <section className="page-banner">
      <div className="container">
        <div className="row align-items-center justify-content-center page-bn-height">
          <div className="col-12 text-right mt-5">
            <h5 className="caption mt-5">
              "Discover a world of wellness with Nutrics - where health meets
              flavor!"
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumb;
