import React from "react";
import Breadcrumb from "../components/Breadcrumb";

function Whoweare() {
  return (
    <section>
      <Breadcrumb />
      <div className="container">
        <div class="row">
          <div class="col-md-6">
            <h3>Our Mission</h3>
            <p className="text-justify">
              "Empower health with Nutrics: Your source for evidence-based
              nutrition, personalized guidance, and a supportive community. We
              make nutrition accessible, understandable, and transformative for
              a nourished and balanced life."
            </p>
          </div>
          <div class="col-md-6">
            <h3>Our Vision</h3>
            <p className="text-justify">
              "Nutrics envisions a healthier world, empowering individuals with
              informed nutrition choices. We foster a global community that
              embraces well-being, balance, and vitality through cutting-edge
              information and personalized support."
            </p>
          </div>
        </div>
      </div>

      <section className="promotion-area">
        <div class="appoinmentshpae-1">
          <div class="innershape"></div>
        </div>
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6 section-wrapper no-margin text-md-center">
              <h3
                className="section-title animation animated"
                data-animation="fadeInDown"
                data-animation-delay="0.1s"
              >
                Over 15+ Years
                <br />
                {/* <h5 className="mt-0 mt-2 counter">
                Experienced as a certified nutritionist & Diabetic Educator
              </h5> */}
              </h3>
              <p className="text-justify">
                As a professional nutritionist and Dietitian, my primary
                objective is to disseminate comprehensive knowledge and
                awareness regarding the adoption of healthy eating habits. I am
                committed to assisting individuals in achieving their unique
                nutritional objectives, with a strong focus on practical skills,
                everyday food choices, and offering the latest evidence-based
                guidance. My approach involves the provision of personalized
                diet plans tailored to accommodate individual preferences,
                health status, occupational demands, family dynamics, and
                lifestyle considerations.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div
                  className="col-sm-6 animation animated fadeInDown"
                  data-animation="fadeInDown"
                  data-animation-delay="0.1s"
                >
                  <div className="media single-cosunter-block">
                    <img
                      className="mr-3"
                      src="assets/images/all-img/counter_icon1.png"
                      alt=""
                    />
                    <div className="media-body">
                      <h5 className="mt-0 counter">100+</h5>
                      <p>Workout Sessions</p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-6 animation animated fadeInDown"
                  data-animation="fadeInDown"
                  data-animation-delay="0.1s"
                >
                  <div className="media single-cosunter-block">
                    <img
                      className="mr-3"
                      src="assets/images/all-img/counter_icon2.png"
                      alt=""
                    />
                    <div className="media-body">
                      <h5 className="mt-0 counter">500+</h5>
                      <p>Happy Customers</p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-6 animation animated fadeInUp"
                  data-animation="fadeInUp"
                  data-animation-delay="0.1s"
                >
                  <div className="media single-cosunter-block">
                    <img
                      className="mr-3"
                      src="assets/images/all-img/counter_icon3.png"
                      alt=""
                    />
                    <div className="media-body">
                      <h5 className="mt-0 counter">100+</h5>
                      <p>Fat loss diet</p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-6 animation animated fadeInUp"
                  data-animation="fadeInUp"
                  data-animation-delay="0.1s"
                >
                  <div className="media single-cosunter-block">
                    <img
                      className="mr-3"
                      src="assets/images/all-img/counter_icon4.png"
                      alt=""
                    />
                    <div className="media-body">
                      <h5 className="mt-0 counter">60+</h5>
                      <p>Days Of Program</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="appoinmentshpae-2">
          <div class="innershape"></div>
        </div>
      </section>

      <div className="container mt-200">
        <div className="container">
          <div
            className="row g-5 align-items-center wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div className="col-lg-6">
              <div className="row">
                <div className="about-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="../images/nutritionist-upscaled.png"
                    alt="nutriction"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <p className="mb-4 text-justify">
                {/* I am Suguna, a certified nutritionist and Diabetic Educator,
                boasting over 15 years of experience and specialized expertise
                in clinical nutrition.
                <br /> */}
                My focus lies in effectively managing Diabetes (both type 1 and
                type 2), gestational diabetes, weight management, digestive and
                pediatric nutrition, as well as addressing infertility and
                overall women's wellness.
                <br />
              </p>
              <p className="mb-4 text-justify">
                My passion lies in fostering respect and a profound
                understanding within my clients regarding their personal bodily
                needs. This understanding encompasses their food preferences,
                work styles, family dynamics, and overall lifestyle to attain
                optimal health.
              </p>
              <p className="mb-4 text-justify">
                As a dedicated nutritionist and Dietitian, my overarching
                mission is to disseminate comprehensive knowledge and awareness
                about healthy eating habits among the general population. I aim
                to empower my clients with a deep understanding of their
                nutritional needs, equipping them with the knowledge and tools
                necessary to lead disease-free, healthy lives. I provide
                personalized diet plans that cater to your food preferences,
                health objectives, work commitments, family dynamics, and
                lifestyle choices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whoweare;
