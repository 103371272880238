import React from "react";
import Breadcrumb from "../components/Breadcrumb";

function Testimonials() {
  return (
    <section>
      <Breadcrumb />
      <div className="container review_text-active slick-initialized slick-slider p-0">
        <section class="client-review">
          <div class="container">
            <div class="row ">
              <div class="col-lg-7">
                <div class="row">
                  <h3>Clients Testimonials</h3>
                  <div id="carouselExampleIndicators" class="carousel slide">
                    <div class="carousel-indicators testimonials ">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        class="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div class="review_text-active slick-initialized slick-slider"></div>

                      <div class="carousel-item active">
                        <div
                          class="single-review-text d-flex flex-column justify-content-center align-items-center text-center slick-slide slick-current slick-active"
                          data-slick-index="0"
                          aria-hidden="false"
                          tabindex="0"
                        >
                          <div class="client-large-thumb text-center">
                            <img
                              src="/images/clientlg1.jpg"
                              class="img-thumbnail rounded-circle"
                              alt=""
                            />
                          </div>
                          <p>
                            I feel super positive and energetic - the feelings
                            that I did not know of before.
                          </p>
                          <h4>Steve</h4>
                          <span>Sr. Manager</span>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div
                          class="single-review-text d-flex flex-column justify-content-center align-items-center text-center slick-slide slick-current slick-active"
                          data-slick-index="0"
                          aria-hidden="false"
                          tabindex="0"
                        >
                          <div class="client-large-thumb text-center">
                            <img
                              src="/images/client_reivewsm2.jpg"
                              class="img-thumbnail rounded-circle"
                              alt=""
                            />
                          </div>
                          <p>
                            I feel super positive and energetic - the feelings
                            that I did not know of before.
                          </p>
                          <h4>Priya</h4>
                          <span>Home Maker</span>
                        </div>
                      </div>

                      <div class="carousel-item">
                        <div
                          class="single-review-text d-flex flex-column justify-content-center align-items-center text-center slick-slide slick-current slick-active"
                          data-slick-index="0"
                          aria-hidden="false"
                          tabindex="0"
                        >
                          <div class="client-large-thumb text-center">
                            <img
                              src="/images/comments2.jpg"
                              class="img-thumbnail rounded-circle"
                              alt=""
                            />
                          </div>
                          <p>
                            I feel super positive and energetic - the feelings
                            that I did not know of before.
                          </p>
                          <h4>Shilpa</h4>
                          <span>Architect</span>
                        </div>
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 bmi-calcalutaer offset-lg-1">
                <h3>BMI Calculator</h3>
                <form>
                  <h4>height</h4>
                  <div class="form-group row">
                    <label for="ft" class="col-sm-2 col-form-label">
                      FT
                    </label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" id="ft" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="in" class="col-sm-2 col-form-label">
                      IN
                    </label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" id="in" />
                    </div>
                  </div>
                  <h4>weight</h4>
                  <div class="form-group row">
                    <label for="lbs" class="col-sm-2 col-form-label">
                      LBS
                    </label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" id="lbs" />
                    </div>
                  </div>
                  <div class="text-right">
                    <button type="submit"> submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Testimonials;
