import React from "react";

function HomePage() {
  return (
    <div>
      <section className="site-slider bg-1">
        <div className="text-slide-active owl-carousel owl-loaded owl-drag container">
          <div className="owl-stage-outer">
            <div className="owl-stage">
              <div className="owl-item cloned">
                <div className="single-slide">
                  <div className="container">
                    <div className="row bn_height align-items-center">
                      <div className="col-lg-7">
                        <div className="slide-content">
                          <h1 className="animated bounceIn">
                            It is time to balance your life &amp;{" "}
                            <span>Nourish your Soul!</span>
                          </h1>
                          <p className="animated flipInX">
                            Prioritize Self-Care | Establish Healthy Habits |
                            Cultivate Positive Relationships | Practice
                            Mindfulness | Explore Hobbies & Passions | Connect
                            with Your Spirituality
                          </p>
                          <a
                            href="#"
                            className="btn-mr th-gradient pill animated slideInLeft"
                          >
                            Register Now
                          </a>
                          <a
                            href="/Contactus"
                            className="btn-mr th-gradient2 pill animated slideInRight"
                          >
                            Contacts Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="owl-nav disabled">
            <div className="owl-prev">prev</div>
            <div className="owl-next">next</div>
          </div>
          <div className="owl-dots">
            <div className="owl-dot">
              <span></span>
            </div>
            <div className="owl-dot active">
              <span></span>
            </div>
          </div>
        </div>
      </section>

      <section className="health-guide">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 section-wrapper text-center">
              <h3 className="section-title">Why Health Guide?</h3>
              <p>
                A health guide serves as a valuable resource to help individuals
                make informed decisions about their well-being and adopt
                healthier lifestyles.
              </p>
            </div>
          </div>
          <div className="row grid">
            <div
              className="col-lg-4 col-md-6 text-center grid-item animation"
              data-animation="fadeInLeft"
              data-animation-delay="0.1s"
            >
              <div className="single-health-block  d-flex flex-column align-items-center justify-content-center">
                <div className="icon-box">
                  <i className="icofont">broccoli</i>
                </div>
                <h4>Nutrition Strategies</h4>
                <p style={{ textAlign: "justify" }}>
                  Nutrition is a key factor in maintaining overall health and
                  well-being. Different individuals may have different
                  nutritional needs based on factors such as age, gender,
                  activity level, health status, and specific goals.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 grid-item animation"
              data-animation="zoomIn"
              data-animation-delay="0.7s"
            >
              <div className="single-health-block-big text-center">
                <img src="../images/health_add.png" alt="" />
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 text-center grid-item  animation"
              data-animation="fadeInRight"
              data-animation-delay="0.3s"
            >
              <div className="single-health-block  d-flex flex-column align-items-center justify-content-center">
                <div className="icon-box">
                  <i className="icofont">live_support</i>
                </div>
                <h4>Individual Support</h4>
                <p style={{ textAlign: "justify" }}>
                  Individual support of health and well-being can encompass
                  various aspects, including emotional, physical, and
                  informational support healthy choices regarding their dietary
                  habits.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 text-center grid-item animation"
              data-animation="fadeInLeft"
              data-animation-delay="0.2s"
            >
              <div className="single-health-block  d-flex flex-column align-items-center justify-content-center">
                <div className="icon-box">
                  <i className="icofont">runner</i>
                </div>
                <h4>Exercise Daily</h4>
                <p style={{ textAlign: "justify" }}>
                  Engaging in regular physical activity is essential for
                  maintaining good health and well-being. The American Heart
                  Association and other health organizations recommend at least
                  150 minutes of moderate-intensity aerobic exercise or 75
                  minutes of vigorous-intensity aerobic exercise per week, along
                  with muscle-strengthening activities at least two days a week.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 text-center grid-item animation"
              data-animation="fadeInRight"
              data-animation-delay="0.0.4s"
            >
              <div className="single-health-block  d-flex flex-column align-items-center justify-content-center">
                <div className="icon-box">
                  <i className="icofont">food_basket</i>
                </div>
                <h4>Healthy Diets</h4>
                <p style={{ textAlign: "justify" }}>
                  Maintaining a healthy diet is crucial for overall well-being,
                  providing essential nutrients to support proper body function
                  and prevent the risk of chronic diseases.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 text-center grid-item animation"
              data-animation="fadeInUp"
              data-animation-delay="0.6s"
            >
              <div className="single-health-block  d-flex flex-column align-items-center justify-content-center">
                <div className="icon-box">
                  <i className="icofont">medicine</i>
                </div>
                <h4>Preventive Health Measures</h4>
                <p style={{ textAlign: "justify" }}>
                  Health guides often include tips on preventive measures such
                  as vaccinations, screenings, and healthy habits. These
                  measures can help individuals identify and address health
                  issues before they become more serious.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 text-center text-md-left">
              <h3>The fastest way to loss weight in natural way!</h3>
              <p>
                Losing weight in a healthy and sustainable way involves a
                combination of a balanced diet, regular physical activity, and
                lifestyle changes. It's important to approach weight loss with a
                focus on overall well-being rather than seeking quick fixes, as
                rapid weight loss can often be detrimental to your health.
              </p>
            </div>
            <div className="col-md-5 text-md-right text-center">
              <a href="/Contactus">GET A FREE CONSULTATION!</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
