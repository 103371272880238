import React from "react";
import Breadcrumb from "../components/Breadcrumb";

function Faq() {
  return (
    <section>
      <Breadcrumb />

      <div class="container">
        <div class="spacer-extra"></div>
        <div class="row">
          <div class="col-12">
            <h3>FAQ's</h3>
            <p>
              Certainly! How may I assist you with frequently asked questions
              (FAQs)? If you have specific questions or topics you'd like
              information on, feel free to let me know or refer below,
            </p>
            <div class="accordion mt-3" id="1">
              <div class="card">
                <div class="card-header card-primary" id="1">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq1"
                        aria-expanded="false"
                        aria-controls="faq1"
                      >
                        1. Can I pay in installments?
                      </button>
                    </h2>
                    <div
                      id="faq1"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        No. you cannot pay in installments.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                        aria-expanded="false"
                        aria-controls="faq2"
                      >
                        2. What happens once I make the payment?
                      </button>
                    </h2>
                    <div
                      id="faq2"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        We will generate your appointment schedule for the
                        consultation call and send you a Front sheet. This
                        contains all the info we require from you to start the
                        program.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class=" collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq3"
                        aria-expanded="false"
                        aria-controls="faq3"
                      >
                        3. Is there any couple / family discount?
                      </button>
                    </h2>
                    <div
                      id="faq3"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        There are a discounts for any of the programs
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class=" collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq4"
                        aria-expanded="false"
                        aria-controls="faq4"
                      >
                        4. Can I upgrade my plan?
                      </button>
                    </h2>
                    <div
                      id="faq4"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        You can upgrade and renew your plan if you wish to.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq5"
                        aria-expanded="false"
                        aria-controls="faq5"
                      >
                        5. How can I connect with the nutritionist?
                      </button>
                    </h2>
                    <div
                      id="faq5"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        You can text them via WhatsApp and if you want to the
                        call, it will be scheduled after that. Please be patient
                        and wait for your nutritionist to get back to you.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq6"
                        aria-expanded="false"
                        aria-controls="faq6"
                      >
                        6. Can we take the plan without the blood tests?
                      </button>
                    </h2>
                    <div
                      id="faq6"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        A nutrition plan can be made without blood tests. But
                        supplements cannot be given without a blood test
                        highlighting nutrient deficiencies. Your plan will not
                        be as holistic as you may want it to be, since
                        deficiencies will not be addressed.{" "}
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq7"
                        aria-expanded="false"
                        aria-controls="faq7"
                      >
                        7. What if the plan doesn't work?
                      </button>
                    </h2>
                    <div
                      id="faq7"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        There are many reasons for the diet plan not to work.
                        Please keep in touch with us. We will get back to you
                        and help you to understand the reason for the failure.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq8"
                        aria-expanded="false"
                        aria-controls="faq8"
                      >
                        8. Will the diet be easy to follow?
                      </button>
                    </h2>
                    <div
                      id="faq8"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        Yes it will be easy to follow the diet because it is
                        customized based on your food preferences and your likes
                        and dislikes.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq9"
                        aria-expanded="false"
                        aria-controls="faq9"
                      >
                        9. How do I make the payment?
                      </button>
                    </h2>
                    <div
                      id="faq9"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        Please click on the 'Sign Up' button on this page, to
                        see the modes of payment. Clients can pay online through
                        bank transfer or GPay or Phone Pay. Please note that we
                        do not accept payment via cash.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq10"
                        aria-expanded="false"
                        aria-controls="faq10"
                      >
                        10. The FAQs above don't cover my query. How do I reach
                        you?
                      </button>
                    </h2>
                    <div
                      id="faq10"
                      class="accordion-collapse collapse"
                      data-bs-parent="#1"
                    >
                      <div class="accordion-body">
                        The FAQ's here aren't exhaustive as every individual
                        will seek different answers. If you don't find your
                        answer here, please feel free to msg me in whatsapp @
                        8884442976 and I'll revert to you within 24-48 working
                        hours.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="spacer-extra"></div>

        <div class="spacer-extra"></div>
      </div>

      <section className="call-to-action">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 text-center text-md-left">
              <h3>The fastest way to loss weight in natural way!</h3>
              <p>
                Losing weight in a healthy and sustainable way involves a
                combination of a balanced diet, regular physical activity, and
                lifestyle changes. It's important to approach weight loss with a
                focus on overall well-being rather than seeking quick fixes, as
                rapid weight loss can often be detrimental to your health.
              </p>
            </div>
            <div className="col-md-5 text-md-right text-center">
              <a href="#">GET A FREE CONSULTATION!</a>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Faq;
