import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [scrollClass, setScrollClass] = useState("");
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 992) {
        if (window.scrollY > 45) {
          setScrollClass("bg-white shadow");
        } else {
          setScrollClass("");
        }
      } else {
        if (window.scrollY > 45) {
          setScrollClass("bg-green shadow");
        } else {
          setScrollClass("");
        }
      }
    };
    // Attach the scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="site-header">
      <div
        className={`container-fluid px-0 wow fadeIn ${scrollClass}`}
        data-wow-delay="0.1s"
      >
        <div className="top-bar row gx-0 align-items-center d-none d-lg-flex"></div>
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <ul className="text-left text-md-left lft">
                  <li>
                    <a href="mailto:diet@nutrics.co.in">diet@nutrics.co.in</a>
                  </li>
                  <li>
                    <i className="icofont">ui_timer</i>Mon - Sat | 10am to 7pm
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-4">
                <ul className="text-right text-md-right rgt ico_header">
                  <li>
                    <Link to="#">
                      <a>
                        <i className="icofont">facebook</i>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <a>
                        <i className="icofont">twitter</i>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <a>
                        <i className="icofont">blogger</i>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <a>
                        <i className="icofont">linkedin</i>
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <nav class="container navbar navbar-expand-lg">
          <div class="container-fluid main-navigation">
            <Link to="/">
              <a className="navbar-brand logo">
                <img
                  src="/images/logo/nutrics-white.svg"
                  alt=""
                  width={"75%"}
                />
              </a>
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarNavDropdown"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <Link to="/">
                    <a class="nav-link active" aria-current="page">
                      Home
                    </a>
                  </Link>
                </li>

                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Who We are
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/Whoweare">
                        <a class="dropdown-item">About us</a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Testimonials">
                        <a class="dropdown-item">Testimonials</a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/SuccessStories">
                        <a class="dropdown-item">SuccessStories</a>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                  </a>

                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/Services">
                        <a class="dropdown-item">Our Sevices</a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/NewsandArticles">
                        <a class="dropdown-item">News & Articles</a>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <a class="dropdown-item">Healthy Recipes</a>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li class="nav-item">
                  <Link><a class="nav-link" >
                    Our Gallery
                  </a></Link>
                </li> */}

                <li class="nav-item">
                  <Link to="#">
                    <a class="nav-link">Our Blog</a>
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/Faq">
                    <a class="nav-link">Faq's</a>
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/Contactus">
                    <a class="nav-link">Conatct Us</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
