import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import "../node_modules/wow.js/dist/wow.min.js";
import WOW from "wow.js";
import "../node_modules/owl.carousel/dist/assets/owl.carousel.css";

import "./App.css";
import "./plugins.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Breadcrumb from "./components/Breadcrumb";
import Contactus from "./pages/Contactus.js";
import Whoweare from "./pages/Whoweare";
import HomePage from "./pages/HomePage";
import Testimonials from "./pages/Testimonials.js";
import SuccessStories from "./pages/SuccessStories.js";
import Services from "./pages/Services.js";
import NewsandArticles from "./pages/NewsandArticles.js";
import FAQ from "./pages/Faq.js";
import Contactform from "../src/pages/ContactForm.php";

// import CustomDietPlan from "./pages/CustomDietPlan";

// import Gallery from "./pages/Gallery";
// import MakeAnAppointment from "./pages/MakeAnAppointment";
// import NewsArticle from "./pages/NewsArticle";
// import Promotion from "./pages/Promotion";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <Router>
      <div className="App site-slider">
        <Header />
        <main>
          {isLoading ? (
            <div class="preloader-wrapper">
              <div class="preloader-thumb">
                <img src="../images/preloder.gif" alt="" />
              </div>
            </div>
          ) : (
            // Render your content when not loading
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Whoweare" element={<Whoweare />} />
              <Route path="/Contactus" element={<Contactus />} />
              <Route path="/Testimonials" element={<Testimonials />} />
              <Route path="/SuccessStories" element={<SuccessStories />} />
              <Route path="/Services" element={<Services />} />
              <Route path="/NewsandArticles" element={<NewsandArticles />} />
              <Route path="/FAQ" element={<FAQ />} />

              {/*<Route path="/CustomDietPlan" element={<CustomDietPlan />} />
              
              <Route
                path="/MakeAnAppointment"
                element={<MakeAnAppointment />}
              />
              
              {/* <Route path="/registration" element={<Registration />} /> */}
            </Routes>
          )}
        </main>

        {/* <div className="single-contact-innfo d-flex flex-column justify-content-center align-items-center">
          <div className="boxicon mr-3">
            <i
              className="icofont whatsapp"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://wa.me/918884442976", "_blank")
              }
            >
              whatsapp
            </i>
          </div>
        </div> */}

        <div className=" btn-whatsapp rounded-circle">
          <a href="#" className="btn btn-lg ">
            <i
              className="icofont whatsapp"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://wa.me/918884442976", "_blank")
              }
            >
              whatsapp
            </i>
          </a>
        </div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
