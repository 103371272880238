import React from "react";

function Footer() {
  return (
    <footer className="site-footer mt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-footer">
              <h3 className="footer-titil">Reach Us</h3>
              <p>
                <i className="fa fa-map-marker me-3"></i>591, HBR Layout, Kalyan
                Nagar, Bangalore.
                <br />
                <p>
                  <i className="fa fa-envelope me-3"></i>
                  <a href="mailto:diet@nutrics.co.in">diet@nutrics.co.in</a>
                </p>
              </p>

              <address>
                Get your Appointment! <br />
                <strong>+91 88844 42976</strong>
              </address>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-footer">
              <h3 className="footer-titil">RECENT POST</h3>
              <ul className="post-link">
                <li>
                  <a href="#">5 Simple & Healthy Gluten Free Cookie</a>
                </li>
                <li>
                  <a href="#">How much calories do i needs to burn?</a>
                </li>
                <li>
                  <a href="#">6 Tip to make tummy happy</a>
                </li>
                <li>
                  <a href="#">Your Daily activity</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-footer">
              <h3 className="footer-titil">NEWS LETTER</h3>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder=" enter your email"
                />
                <div className="input-group-prepend">
                  <button className="btn" type="button" id="button-addon1">
                    Button
                  </button>
                </div>
              </div>
              <ul className="footer-social">
                <li>
                  <a href="#">
                    <i className="icofont">facebook</i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icofont">twitter</i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icofont">blogger</i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icofont">linkedin</i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 text-center">
            <div className="copy-right">
              <p>2024 &copy; Nutrics India, All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-decoration">
        <div className="fbox-1"></div>
        <div className="fbox-2"></div>
      </div>
    </footer>
  );
}

export default Footer;
