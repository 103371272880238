import React from "react";
import Breadcrumb from "../components/Breadcrumb";

function Services() {
  return (
    <section>
      <Breadcrumb />

      <div className="container">
        <div className="tab-content">
          <div id="tab-1" className="tab-pane fade show p-0 active">
            <div className="row g-4">
              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-1.jpg"
                      alt="Diabetes"
                    />
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2"> Diabetes</p>
                    <span className="me-1">
                      (Pre Diabetic, type1and type 2 diabetes, gestational
                      diabetes)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-2.jpg"
                      alt="Cardiovascular Diseases"
                    />
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Cardiovascular Diseases</p>
                    <span className="me-1">
                      (High cholesterol, high BP, heart attack, stroke and other
                      heart related risk problems)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-3.jpg"
                      alt="Weight Management"
                    />
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Weight Management</p>
                    <span className="me-1">(Weight loss or weight gain)</span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-4.jpg"
                      alt="Digestive Problems"
                    />
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Digestive Problems </p>
                    <span className="me-1">
                      (Ulcer, GERD, gall stones, irritable bowel syndrome,
                      Crohn's disease, hemorrhoids, gluten insensitivity,
                      lactose intolerance, pancreatitis)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-5.jpg"
                      alt="Lung Problems"
                    />
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Lung Problems </p>
                    <span className="me-1">(COPD, TB, HRAD)</span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-6.jpg"
                      alt="Kidney Problems"
                    />
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Kidney Problems</p>
                    <span className="me-1">
                      (Kidney stones, kidney failure, dialysis and non-dialysis
                      treatment)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-7.jpg"
                      alt="Liver Problems"
                    />
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Liver Problems</p>
                    <span className="me-1">
                      (Jaundice, liver cirrhosis and fatty liver)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-8.jpg"
                      alt="Pediatric Problems"
                    />
                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                      New
                    </div>
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Pediatric Problems </p>
                    <span className="me-1">
                      (Childhood obesity, pre - puberty nutrition and general
                      wellness)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.9s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-9.jpg"
                      alt="Women Wellness"
                    />
                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                      New
                    </div>
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Women Wellness</p>
                    <span className="me-1">
                      (Infertility, Fibroids, endometriosis, Adenomyosis, pre
                      and post-menopausal diet)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.11s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-10.jpg"
                      alt="Geriatric & General Care"
                    />
                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                      New
                    </div>
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Geriatric & General Care </p>
                    <span className="me-1">
                      (elderly nutrition, Osteoporosis, Anemia, Vitamin B12,
                      Vitamin D and all micro and macro nutrient deficiencies,
                      Skin and health care, healthy eating and nutrition)
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.13s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-11.jpg"
                      alt="Stress and Mental health"
                    />
                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                      New
                    </div>
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Stress and Mental health </p>
                    <span className="me-1">Stress and Mental health</span>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.15s"
              >
                <div className="product-item">
                  <div className="position-relative bg-light overflow-hidden">
                    <img
                      className="img-fluid w-100"
                      src="../images/product-12.jpg"
                      alt="Skin and hair Care"
                    />
                    <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                      New
                    </div>
                  </div>
                  <div className="text-center p-4">
                    <p className="d-block h5 mb-2">Skin and hair Care </p>
                    <span className="me-1">Skin and hair Care</span>
                  </div>
                </div>
              </div>

              <div
                className="col-12 text-center wow fadeInUp"
                data-wow-delay="0.13s"
              >
                <p className="btn btn-primary rounded-pill py-3 px-5">
                  More Services..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="call-to-action">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 text-center text-md-left">
              <h3>The fastest way to loss weight in natural way!</h3>
              <p>
                Losing weight in a healthy and sustainable way involves a
                combination of a balanced diet, regular physical activity, and
                lifestyle changes. It's important to approach weight loss with a
                focus on overall well-being rather than seeking quick fixes, as
                rapid weight loss can often be detrimental to your health.
              </p>
            </div>
            <div className="col-md-5 text-md-right text-center">
              <a href="/Contactus">GET A FREE CONSULTATION!</a>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Services;
