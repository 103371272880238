import React from "react";
import Breadcrumb from "../components/Breadcrumb";

function SuccessStories() {
  return (
    <section>
      <Breadcrumb />
      <div className="container ">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-touch="true"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="owl-item active">
                <div class="col-12 single_stroy">
                  <div class="row">
                    <div class="col-lg-6 col-md-7">
                      <h3>Story Of Success</h3>
                      <p>
                        <strong>
                          I feel super positive and energetic - the feelings
                          that I did not know of before.
                        </strong>
                      </p>
                      <p>
                        For me personally, a healthy way of living was a
                        mysterious and unrealistic theory that I was never in a
                        mood to explore. No, with your help and guidance I am to
                        start a new life! It is always easier to go with fast
                        food, but we forget that neglecting your diet costs us
                        our long and happy life. You don't have to be a chef to
                        be able to eat properly. Everyone can afford organic
                        green veggies, fresh fruits and protein today.
                      </p>
                      <h4>Md. Mahedi Amin</h4>
                      <small>MANAGER / CLIENT</small>
                    </div>
                    <div class="col-lg-6 col-md-5 align-self-md-center">
                      <div class="strong-man">
                        <img src="../images/man1.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="owl-item active">
                <div class="col-12 single_stroy">
                  <div class="row">
                    <div class="col-lg-6 col-md-7">
                      <h3 class="section-title">Story Of Success</h3>
                      <p>
                        <strong>
                          I feel super positive and energetic - the feelings
                          that I did not know of before.
                        </strong>
                      </p>
                      <p>
                        For me personally, a healthy way of living was a
                        mysterious and unrealistic theory that I was never in a
                        mood to explore. No, with your help and guidance I am to
                        start a new life! It is always easier to go with fast
                        food, but we forget that neglecting your diet costs us
                        our long and happy life. You don't have to be a chef to
                        be able to eat properly. Everyone can afford organic
                        green veggies, fresh fruits and protein today.
                      </p>
                      <h4>Ameera</h4>
                      <small>CLIENT</small>
                    </div>
                    <div class="col-lg-6 col-md-5 align-self-md-center">
                      <div class="strong-man">
                        <img src="../images/man2.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="owl-item active">
                <div class="col-12 single_stroy">
                  <div class="row">
                    <div class="col-lg-6 col-md-7">
                      <h3 class="section-title">Story Of Success</h3>
                      <p>
                        <strong>
                          I feel super positive and energetic - the feelings
                          that I did not know of before.
                        </strong>
                      </p>
                      <p>
                        For me personally, a healthy way of living was a
                        mysterious and unrealistic theory that I was never in a
                        mood to explore. No, with your help and guidance I am to
                        start a new life! It is always easier to go with fast
                        food, but we forget that neglecting your diet costs us
                        our long and happy life. You don't have to be a chef to
                        be able to eat properly. Everyone can afford organic
                        green veggies, fresh fruits and protein today.
                      </p>
                      <h4>Moideen</h4>
                      <small>CLIENT</small>
                    </div>
                    <div class="col-lg-6 col-md-5 align-self-md-center">
                      <div class="strong-man">
                        <img src="../images/man3.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default SuccessStories;
