import React from "react";
import Breadcrumb from "../components/Breadcrumb";

function NewsandArticles() {
  return (
    <section class="health-guide p-0">
      <Breadcrumb />
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 section-wrapper text-center">
            <h3>Why Health Guide?</h3>
            <p>
              A health guide serves as a valuable resource to help individuals
              make informed decisions about their well-being and adopt healthier
              lifestyles.
            </p>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="media singleHelthBlock">
              <div class="boxicon mr-3">
                <i class="icofont">broccoli</i>
              </div>
              <div class="media-body">
                <h4 class="mt-0">
                  <a href="#">Nutriton Strategies</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet diam nonumy eirmod tempor invidunt
                </p>
                <a href="#">Read More...</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="media singleHelthBlock">
              <div class="boxicon mr-3">
                <i class="icofont">food_basket</i>
              </div>
              <div class="media-body">
                <h4 class="mt-0">
                  <a href="#">Healthy Diets</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet diam nonumy eirmod tempor invidunt
                </p>
                <a href="#">Read More...</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="media singleHelthBlock">
              <div class="boxicon mr-3">
                <i class="icofont">live_support</i>
              </div>
              <div class="media-body">
                <h4 class="mt-0">
                  <a href="#">Individual Support</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet diam nonumy eirmod tempor invidunt
                </p>
                <a href="#">Read More...</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="media singleHelthBlock">
              <div class="boxicon mr-3">
                <i class="icofont">dumbbell</i>
              </div>
              <div class="media-body">
                <h4 class="mt-0">
                  <a href="#">Exercise Daily</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet diam nonumy eirmod tempor invidunt
                </p>
                <a href="#">Read More...</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="media singleHelthBlock">
              <div class="boxicon mr-3">
                <i class="icofont">medicine</i>
              </div>
              <div class="media-body">
                <h4 class="mt-0">
                  <a href="#">Vitamins Tab</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet diam nonumy eirmod tempor invidunt
                </p>
                <a href="#">Read More...</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="media singleHelthBlock">
              <div class="boxicon mr-3">
                <i class="icofont">fast_food</i>
              </div>
              <div class="media-body">
                <h4 class="mt-0">
                  <a href="#">avoid junk food</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet diam nonumy eirmod tempor invidunt
                </p>
                <a href="#">Read More...</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsandArticles;
